import revive_payload_client_8jNtfeoNeFJxySRELqiO7qA6tScrtjjAw4mFpmjHdxg from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_iqaeoengwtbiayrqkb4f6pnihq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wBdQJLiW7Q_eD2pVSiiIcEKjSPceV96Yj83gF9IFe_E from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_iqaeoengwtbiayrqkb4f6pnihq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jtt6N3tWW9L_V9xkjuEjz1bJd7NfjLrG6Pf6TnHB_20 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_iqaeoengwtbiayrqkb4f6pnihq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8VQEVA1j5zozzaZ78tSSkDhJN8g9LPCjt0R4x4vnn8A from "/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.6.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_dFzol4WtG_d5WuxrpSC6O6qgWinQVIvZiH9WcSYeWtc from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_iqaeoengwtbiayrqkb4f6pnihq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mbKm_tVONJRGw1JxwUbKsTd7amPliHN3l0_ci_kUgj4 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_iqaeoengwtbiayrqkb4f6pnihq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dDGzrUSGZ86yLuNX8SDaxblpp8Z3jlVyX1Tll2PhdM8 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_iqaeoengwtbiayrqkb4f6pnihq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Cav_rResjIfMO_kyDu871frKkNOvRXEdWBbpcyvz_sU from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_iqaeoengwtbiayrqkb4f6pnihq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_PDf__yGYGHKeV039PsSqktd4Os6pbSoB1AoGP_y2rfY from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_typescript@5.6.2_vue@3.5.13_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_ozuuEJhXzzHOagKSo0zz0guWxb2HjXhq3X9zlEiTlrw from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_iqaeoengwtbiayrqkb4f6pnihq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_fygoG94hIPM35liKPkr9vhi2bQihGQh1IqePGacV7aE from "/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import siteConfig_cjKJKTnz9EV07JtytXrCNQDe0NMu3wodd7gDgaD1ln8 from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_M3bkagTujs598hRI22I3d__0mrld2qHRNZtthgVEbKk from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_GQFZcPLKxo4mZ5Lqyl4KVvFwcgM5AD5Bcw6stir8BO0 from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import slideovers_023bDPVwCqYXTcEglR8dGkwQJ9vuL_BSOc4zYTlKSoU from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_vite@6.2.2_@ty_sd6k3oksflsvusdo2lyjpoeibm/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_T3COWrkqd0d1TUhqWpS0NwEWd4cExFtZeWHQngeVN0w from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_vite@6.2.2_@ty_sd6k3oksflsvusdo2lyjpoeibm/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_s3rwzArNSfUKAR0Sz9r90n5y_c8VN0bJtfv_yqvUmwY from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_vite@6.2.2_@ty_sd6k3oksflsvusdo2lyjpoeibm/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_W__NdExGsK36lv_9T0NAD7Wbl4Hj_f2tiiQaXM7COls from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_32PaNPAluU8KsCCzCR5BiP6sQjK9ETlCXtU752UzGM0 from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_vite@6.2.2_@types+node@22.6.1_jiti@2.4.2_sass@1.79.3_terser@5_wwntuojsb3p4gtcg27ililykp4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons__epQoWkrA8LX1_RvmKO_D7ep0vAqN_gOZ_Ekcb_B5kw from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.5_vite@6.2.2_@types+node@22.6.1_jiti@2.4.2_sass@1.79.3_ters_le7bo5yoy76tuarcpjcd6dha6a/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_GXDsmcW0ezBE52A6kVHMPnAoFdh5MiVzCkoVLzn3dlI from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.5_vite@6.2.2_@types+node@22.6.1_jiti@2.4.2_sass@1.79.3_ters_le7bo5yoy76tuarcpjcd6dha6a/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_TO8oqN89DZWBzXLSdxNvBdpgxyS7Nhfo7RYuxr5_rfw from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_typescript@5.6.2_vue_uabrosxlfxzppc7xx7wqufvbp4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_Y6D8IjviQKB3EteSmgkh6nr8GGru_rC_DjhQ3lLa_GM from "/app/plugins/directives.ts";
import services_NnjkqHfUt__RGd8DS0zvn3ai_DabuJc7xnW7l3NjHrE from "/app/plugins/services.ts";
import _0_routeRules_yz0k80biJPVanbr7s7vHvYGQAwm_kupL9FQHFy0NjTk from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_JiNSLPVxqPTpE3d65y3mMIrSks_vo_dOqU2IHi5VONY from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_8jNtfeoNeFJxySRELqiO7qA6tScrtjjAw4mFpmjHdxg,
  unhead_wBdQJLiW7Q_eD2pVSiiIcEKjSPceV96Yj83gF9IFe_E,
  router_jtt6N3tWW9L_V9xkjuEjz1bJd7NfjLrG6Pf6TnHB_20,
  _0_siteConfig_8VQEVA1j5zozzaZ78tSSkDhJN8g9LPCjt0R4x4vnn8A,
  payload_client_dFzol4WtG_d5WuxrpSC6O6qgWinQVIvZiH9WcSYeWtc,
  navigation_repaint_client_mbKm_tVONJRGw1JxwUbKsTd7amPliHN3l0_ci_kUgj4,
  check_outdated_build_client_dDGzrUSGZ86yLuNX8SDaxblpp8Z3jlVyX1Tll2PhdM8,
  chunk_reload_client_Cav_rResjIfMO_kyDu871frKkNOvRXEdWBbpcyvz_sU,
  plugin_vue3_PDf__yGYGHKeV039PsSqktd4Os6pbSoB1AoGP_y2rfY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_ozuuEJhXzzHOagKSo0zz0guWxb2HjXhq3X9zlEiTlrw,
  plugin_client_fygoG94hIPM35liKPkr9vhi2bQihGQh1IqePGacV7aE,
  siteConfig_cjKJKTnz9EV07JtytXrCNQDe0NMu3wodd7gDgaD1ln8,
  inferSeoMetaPlugin_M3bkagTujs598hRI22I3d__0mrld2qHRNZtthgVEbKk,
  titles_GQFZcPLKxo4mZ5Lqyl4KVvFwcgM5AD5Bcw6stir8BO0,
  slideovers_023bDPVwCqYXTcEglR8dGkwQJ9vuL_BSOc4zYTlKSoU,
  modals_T3COWrkqd0d1TUhqWpS0NwEWd4cExFtZeWHQngeVN0w,
  colors_s3rwzArNSfUKAR0Sz9r90n5y_c8VN0bJtfv_yqvUmwY,
  plugin_client_W__NdExGsK36lv_9T0NAD7Wbl4Hj_f2tiiQaXM7COls,
  plugin_32PaNPAluU8KsCCzCR5BiP6sQjK9ETlCXtU752UzGM0,
  pwa_icons__epQoWkrA8LX1_RvmKO_D7ep0vAqN_gOZ_Ekcb_B5kw,
  pwa_client_GXDsmcW0ezBE52A6kVHMPnAoFdh5MiVzCkoVLzn3dlI,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  plugin_TO8oqN89DZWBzXLSdxNvBdpgxyS7Nhfo7RYuxr5_rfw,
  directives_Y6D8IjviQKB3EteSmgkh6nr8GGru_rC_DjhQ3lLa_GM,
  services_NnjkqHfUt__RGd8DS0zvn3ai_DabuJc7xnW7l3NjHrE,
  _0_routeRules_yz0k80biJPVanbr7s7vHvYGQAwm_kupL9FQHFy0NjTk,
  defaults_JiNSLPVxqPTpE3d65y3mMIrSks_vo_dOqU2IHi5VONY
]